@import "../../../assets/style/app";

.createCategoryForm {
  @include block;
  margin-top: 16px;

  @include tablet {
    margin-top: 24px;
  }

  .form {
    margin-top: 8px;

    @include tablet {
      margin-top: 12px;
    }

    .submitBtn {
      margin-top: 8px;

      @include tablet {
        margin-top: 12px;
      }
    }
  }




}