//========= BREAK POINTS ===========//
@mixin tablet {
  @media all and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media all and (min-width: 1240px) {
    @content;
  }
}

@mixin onlyTabletAndLess($displayType: block) {
  display: $displayType;
  @content;

  @include desktop {
    display: none;
  }
}

@mixin onlyMobile($displayType: block) {
  display: $displayType;
  @content;

  @include tablet {
    display: none;
  }
}

@mixin onlyTablet($displayType: block) {
  display: none;

  @include tablet {
    display: $displayType;
    @content;
  }

  @include desktop {
    display: none;
  }
}

@mixin onlyDesktop($displayType: block) {
  display: none;

  @include desktop {
    display: $displayType;
    @content;
  }
}

//========= CONTAINERS ===========//
@mixin container {
  padding-left: 16px;
  padding-right: 16px;

  @include tablet {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include desktop {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin blockPaddings {
  padding-top: 40px;
  padding-bottom: 40px;

  @include tablet {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include desktop {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@mixin inner {
  @include desktop {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin mt {
  margin-top: 16px;

  @include tablet {
    margin-top: 24px;
  }

  @include desktop {
    margin-top: 32px;
  }
}

//========= OTHER ===========//
@mixin block {
  box-sizing: border-box;
  border: 1px solid royalblue;
  border-radius: 8px;
  padding: 8px;

  @include tablet {
    border-radius: 12px;
    padding: 12px;
  }
}


@mixin centerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin centerRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
