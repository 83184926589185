@import "../../assets/style/app";

.content {
  @include container;

  .inner {
    @include inner;
    @include blockPaddings;

    @include tablet {
      display: flex;
    }


    .categoriesBlock {
      @include block;

      .categoriesList {
        @include block;
        margin-top: 16px;

        @include tablet {
          margin-top: 24px;
        }
      }
    }

    .productsBlock {
      @include block;
      margin-top: 16px;

      @include tablet {
        flex-grow: 1;
        margin-top: 0;
        margin-left: 16px;
      }
    }
  }
}