@import "../../assets/style/app";

.header {
  @include container;
  background: royalblue;

  .inner {
    @include inner;
    display: flex;
    align-items: center;
    height: 60px;

    @include tablet {
      height: 80px;
    }

    .logo {
      text-transform: uppercase;
      color: #FFF;
      font-weight: bold;
      font-size: 24px;

      @include tablet {
        font-size: 36px;
      }

    }
  }
}